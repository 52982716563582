:root {
  /* --primary: rgb(201, 197, 206); */
  --secondary: rgb(147, 154, 168);
}

/*  */
/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--secondary) var(--primary);
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 5px;
  /* display: none; */
}

*::-webkit-scrollbar-track {
  background: var(--primary);
  border-radius: 5px;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--secondary);
  border-radius: 10px;
  /* border: 1px solid var(--primary); */
}

@media only screen and (max-width: 768px) {
  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    display: none;
  }
}
